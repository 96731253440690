import React from "react"
import { Container } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"

import { MainLayout } from "../components/layouts/layout"
import JkfImageGallery from "../components/sections/jkf-gallery-section"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/products">
      <SEO
        title="Låge - Farver"
        description="Danmarks største udvalg i farver  og strukturer! Vi er rigtig stolte over at kunne tilbyde, nok Danmarks største udvalg i kvalitets folier og strukturer. Herunder kan du se et mindre udvalg af vores sortiment af folier og strukturer – men de skal ses og mærkes! Farven på din nye skuffe- og lågefront er en højkvalitetsfolie […]"
      />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="stort udvalg af låger"
        description="Låger i høj kvalitet, produceret i danmark"
      />
      <Container fluid>
        <JkfImageGallery images={data}></JkfImageGallery>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    files: allFile(
      filter: { relativeDirectory: { eq: "laager/laage_farver" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxHeight: 300, maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    }
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
  }
`
